<template>
  <div>
    <div class="cases">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">医教产品</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="caselist">
        <ul>
          <li v-for="(item,index) in list[currpage - 1]" :key="index" @click="routerlink(item.articleId,index)">
            <div class="caseimg">
              <img :src="item.articleCover" alt="" />
            </div>
            <div class="casetit">{{item.articleTitle}}</div>
          </li>

          <!-- <li>
            <div class="caseimg">
              <img
                src="~@/assets/images/05数字医教/医教信息化/临床技能中心综合管理系统.png"
                alt=""
              />
            </div>
            <div class="casetit">临床技能中心综合管理系统</div>
          </li>
          <li>
            <div class="caseimg">
              <img
                src="~@/assets/images/05数字医教/医教信息化/临床胜任力训练与考核系统.png"
                alt=""
              />
            </div>
            <div class="casetit">临床胜任力训练与考核系统</div>
          </li>
          <li>
            <div class="caseimg">
              <img
                src="~@/assets/images/05数字医教/医教信息化/临床思维训练系统.png"
                alt=""
              />
            </div>
            <div class="casetit">临床思维训练系统</div>
          </li>
          <li>
            <div class="caseimg">
              <img
                src="~@/assets/images/05数字医教/医教信息化/医学在线考试系统.png"
                alt=""
              />
            </div>
            <div class="casetit">医学在线考试系统</div>
          </li>
          <li>
            <div class="caseimg">
              <img
                src="~@/assets/images/05数字医教/医教信息化/OSCE考核管理系统.png"
                alt=""
              />
            </div>
            <div class="casetit">OSCE考核管理系统</div>
          </li> -->
        </ul>
        <div class="pagination">
          <div class="prevbtn" @click="prevpage()"></div>
          <div class="page">
            <ul>
              <li v-for="(item, index) in pages" :key="index" :class="index + 1 === currpage ? 'hover' : ''" @click="choosepage(index)"></li>
            </ul>
          </div>
           <!-- @mouseover="stopAutoSwitch" @mouseout="startAutoSwitch" -->
          <div class="nextbtn" @click="nextpage()"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getbannerdetail } from "@/http/api/user.js";
export default {
  data() {
    return {
      bannerimg: "",
      list: "",
      pages: 0,
      currpage: 1,
      timer: "",
    };
  },
  mounted() {
    // this.startAutoSwitch(); // 页面加载完成后开始自动分页

    this.getcompany();
  },
  created() {},
  methods: {
    // startAutoSwitch() {

    //   this.timer = setInterval(() => {
    //     this.nextpage();
    //   }, 3000); // 每隔3秒自动切换下一页
    // },
    // stopAutoSwitch() {
    //   clearInterval(this.timer);
    // },
    prevpage() {
      if (this.currpage > 1) {
        this.currpage--;
      } else {
        this.currpage = this.pages;
      }
    },
    nextpage() {
      if (this.currpage < this.pages) {
        this.currpage++;
      } else {
        this.currpage = 1;
      }
    },
    choosepage(index) {
      this.currpage = index + 1;
    },
    routerlink(id, index) {
      this.$router.push({
        path: `/medicaleducation/infodetail`,
        query: {
          id: id,
          index: index,
        },
      });
    },
    //获取公司简介
    async getcompany() {
      let params = {
        barId: 7,
      };
      const data = await getbannerdetail(params);
      var res = data.data.children[0].portalArticleList;
      var arr = [];
      for (let i = 0; i < Math.ceil(res.length / 6); i++) {
        arr[i] = new Array();
        for (let j = i * 6; j < (i + 1) * 6; j++) {
          if (res[j]) {
            arr[i].push(res[j]);
          }
        }
      }
      for (let k = 0; k < arr.length; k++) {
        for (let t = 0; t < arr[k].length; t++) {
          let text = arr[k][t].articleCover;

          // http://36.152.65.166:10008
          arr[k][t].articleCover = "http://36.152.65.166:10008" + text;
        }
      }
      this.list = arr;

      this.pages = Math.ceil(res.length / 6);

      // for (let i = 0; i < this.list.length; i++) {
      //   let text = this.list[i].articleCover;
      //   this.list[i].articleCover = "http://36.152.65.166:10008" + text;
      // }
    },
  },
  beforeDestroy() {
    // this.stopAutoSwitch();
  },
};
</script>

<style>
</style>
<style lang="scss" scoped>
.cases {
  .pagination {
    width: 320px;
    margin: 0px auto;
    margin-top: -50px;
    margin-bottom: 50px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page {
      width: 30%;
      height: 26px;

      ul {
        padding: 0;
        margin: 0;
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        li {
          cursor: pointer;

          width: 10px;
          height: 10px;
          background-color: #cccccc;
          border-radius: 50%;
        }
      }
      .hover {
        background-color: #666666;
      }
    }
    .prevbtn,
    .nextbtn {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
    .prevbtn {
      background: url("~@/assets/images/01首页/上一页1.png");
      background-size: contain !important;
    }
    .nextbtn {
      background: url("~@/assets/images/01首页/下一页1.png");
      background-size: contain !important;
    }
    .prevbtn:hover {
      background: url("~@/assets/images/01首页/上一页2.png");
      background-size: contain !important;
    }
    .nextbtn:hover {
      background: url("~@/assets/images/01首页/下一页2.png");
      background-size: contain !important;
    }
  }
  .caselist {
    width: 1400px;
    margin: 0 auto;
    ul {
      width: 100%;
      margin-bottom: 112px;
      margin-top: 49px;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      justify-content: space-around;
      li {
        height: 340px;
        width: 420px;
        background-color: #e8eaf2;
        text-align: center;
        margin-bottom: 30px;

        .casetit {
          height: 80px;
          line-height: 80px;
          font-size: 20px;
        }
        .caseimg {
          width: 100%;
          height: 260px;
          box-sizing: border-box;
          border: 1px solid gray;
          z-index: 222;
          img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid white;
            z-index: 1;
          }
        }
      }
      li:hover {
        cursor: pointer;
        .casetit {
          background-color: #0088ea;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
}
.product_title {
  width: 1500px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .tit1 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 13px;
    }
    .tit2 {
      width: 60px;
      height: 4px;
      background-color: #0088ea;
    }
  }
}
</style>